<template>
  <div class="world news-detail" v-if="post">
    <div class="news-wrapper">
      <div class="container">
        <div class="news-item">
          <img :src="images[post.image_file_id].url" :alt="getTranslation(post).title" :title="getTranslation(post).title" class="main-image">
          <h1>{{ getTranslation(post).title }}</h1>
          <span class="date">{{ dateTime.fromISO(post.created).setLocale(activeLocale).toFormat('dd.LL.yyyy') }}</span>
          <div class="userContent" v-html="getTranslation(post).body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'

export default {
  data () {
    return {
      postId: this.$route.params.id,
      post: null,
      isLoading: false,
      imagesLoading: false,
      dateTime: DateTime
    }
  },
  async mounted () {
    await ApiService.fetchNewsItem(this.postId).then((response) => {
      this.post = response.data
    })

    if (!this.images[this.post.image_file_id]) {
      this.imagesLoading = true
      await this.$store.dispatch('fetchImages', { objects: [this.post] })
      this.imagesLoading = false
    }
  },
  methods: {},
  computed: {
    images () {
      return this.$store.getters.getImages
    }
  }
}
</script>
